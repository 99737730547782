import React, { useState } from 'react';
import Link from 'next/link';
import { UrlObject } from 'url';

type CommanBtnProps = {
  href?: string | UrlObject;
  className: string;
  text: string;
  onClick?: () => void;
  ariaLabel?: string;
  target?:string;
};

function CommanBtn({ href,  className, text, onClick, ariaLabel, target }: CommanBtnProps) {
  const [position, setPosition] = useState({ top: 0, left: 0 });

  const handleMouseEnter = (e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
    const parentOffset = e.currentTarget.getBoundingClientRect();
    const relX = e.clientX - parentOffset.left;
    const relY = e.clientY - parentOffset.top;
    setPosition({ top: relY, left: relX });
  };

  const handleMouseLeave = (e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
    const parentOffset = e.currentTarget.getBoundingClientRect();
    const relX = e.clientX - parentOffset.left;
    const relY = e.clientY - parentOffset.top;
    setPosition({ top: relY, left: relX });
  };

  const linkProps = {
    href: href || '',
    className: `my_btn ${className}`,
    onMouseEnter: handleMouseEnter,
    onMouseLeave: handleMouseLeave,
    'aria-label': ariaLabel || undefined, // Add aria-label attribute conditionally
    'target':target || undefined
  };

  if (href) {
    return (
      <Link {...linkProps}>
        <span style={{ top: position.top, left: position.left }}></span>
        {text}
      </Link>
    );
  } else {
    return (
      <button
        type="submit"
        className={`my_btn ${className}`}
        onClick={onClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <span style={{ top: position.top, left: position.left }}></span>
        {text}
      </button>
    );
  }
}

export default CommanBtn;
